<template>
  <div class="table-box">
    <div class="flex" style="margin-bottom: 20px;">
      <el-button type="primary" @click="theNewRules">{{$t('codingRule.createRule')}}</el-button>
      <div class="aid">{{$t('codingRule.help')}}</div>
    </div>
    <div>
      <div v-for="item in Rulesdata" :key="item.id" class="regular">
        <div class="flex">
          <div v-if="unfold" class="rulename">
            <span>{{item.name}}</span>
          </div>
          <div v-else class="rulename">
            <el-input v-model="item.name"></el-input>
          </div>
          <div class="ruleIcon">
            <div class="el-icon-s-tools icon-style" @click="theNewRules(item)" ></div>
            <div class="el-icon-delete-solid icon-style" style="color:#cc3434" @click="remove(item)"></div>
          </div>
        </div>
        <div class="subject flex">
          <div
            v-for="(coded,i) in item.codingRuleDetailss"
            :key="i"
            class="subject-coded"
            :class="i == 0 ? 'subject-coded-boder' : ''"
          >
            <div class="subject-text">{{i+1}}</div>
            <div class="subject-text" v-if="coded.type==5">{{coded.name.split("?")[0]}}</div>
              <div class="subject-text" v-else>{{coded.name}}</div>
            <div
              class="subject-text"
              v-if="coded.type==1 ||coded.type==2|| coded.type==3||coded.type==8"
            >{{coded.value}}</div>
            <div v-else-if="coded.type==5" class="subject-text subject-color">{{$t('codingRule.mappingTable')}}:{{coded.name.split("?")[1]}}</div>
            <div v-else-if="coded.type==4" class="subject-text subject-color">{{$t('codingRule.readingFloor')}}</div>
            <div v-else-if="coded.type==6" class="subject-text subject-color subjectcode">{{coded.value}}</div>
            <div v-else class="subject-text subject-color subjectcode">{{$t('codingRule.readParameter')}}:{{coded.value}}</div>
          </div>
        </div>
        <!-- <div class="modalCode">编码:{{item.modalCoding}}</div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      unfold: true,
      Rulesdata: []
    }
  },
  created () {
    this.init()
  },
  methods: {
    theNewRules (row) {
      console.log(row)
      if (row.id) {
        localStorage.setItem('codingRule', JSON.stringify(row))
      } else {
        localStorage.setItem('codingRule', '')
      }
      this.$router.push('/home/newCodingRule')
    },
    async init () {
      let res = await this.$axios.get('/api/codingrule/getcodingrules')
      this.Rulesdata = res
    },
    remove (item) {
      this.$confirm(`删除后无法找回, 是否继续?`, '提示', {
				        	confirmButtonText: '确定',
				        	cancelButtonText: '取消',
				        	type: 'warning'
				        }).then(() => {
				        	this.$axios.get('/api/codingrule/deletecodingrule?id=' + item.id).then(res => {
				        		this.$message({
				        			type: 'success',
				        			message: '删除成功!'
				        		})
				        		this.init()
				        	})
				        })
    }
  }
}
</script>
<style scoped>
.table-box {
  padding: 15px 25px;
  height: calc(100vh - 100px);
  box-sizing: border-box;
  background-color: #e7e7e7;
  font-family: PingFangSC-Medium, PingFang SC;
}
.flex {
  display: -webkit-box;
}
/deep/.el-button--primary {
  background-color: rgb(47 55 74);
  border-color: rgb(47 55 74);
  height: 32px;
  line-height: 1px;
}
.aid {
  color: #10ABF3;
  font-size: 14px;
  font-weight: 600;
  height: 32px;
  line-height: 32px;
  margin-left: 20px;
  cursor: pointer;
}
.regular {
  position: relative;
  background: #ffff;
  height: 189px;
  width: 100%;
  margin: 0 0 10px 0;
  border-radius: 8px;
  color: #5F5F5F;
  padding: 10px;
}
.subjectcode{
  position: relative;
  top: -10px;
}
.rulename {
  font-size: 14px;
  text-align: left;
  height: 34px;
  font-weight: 600;
  line-height: 34px;
  width: 180px;
  text-indent: 8px;
  border-bottom: 1px solid #C8C8C8;
}
/deep/ .el-input__inner {
  border: 0;
  height: 33px;
  font-size: 14px;
  font-weight: 600;
  color: #000;
  padding: 0;
}
.ruleIcon {
  display: flex;
  justify-content: space-between;
  width: 50px;
  align-self: center;
  font-size: 16px;
  margin-left: 20px;
}
.icon-style {
  font-size: 18px;
  cursor: pointer;
}
.modalCode {
  font-size: 15px;
  position: absolute;
  bottom: 20px;
  color: #3333337a;
  font-weight: 600;
}
.subject {
  padding: 10px 0;
  height: 130px;
  overflow: auto;
}

.subject-coded {
  width: 210px;
  border-left: 1px solid #C8C8C8;
  padding-left: 4px;
}
.subject-coded-boder {
  border-left: 0px;
}
.subject-text {
  height: 38px;
  line-height: 38px;
  text-align: left;
  font-size: 14px;
  padding-left:13px;
}
.subject-color {
  color: #50c0e2;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
      width: 96%;
}
</style>
